<template>
  <div class="content" ref="pronbit">
    <div class="topbian" v-if="headerstuts">
      <hidetop
          :pageInfo="pageInfo"
          :typelist="typelist"
          :hotSkillList="hotSkillList"
          :scalelist="scalelist"
          @change="getList"
      >
      </hidetop>
    </div>
    <div class="screenbox">
      <div class="screenbox-mian font16">
        <!-- 企业类型: -->
        <div class="li qiye-type">
          <div class="tit">企业类型:</div>
          <span
              :class="pageInfo.companyType == item1.value ? 'active-c' : ''"
              v-for="(item1, index1) in typelist"
              :key="index1"
              @click="
              (pageInfo.companyType = item1.value),
                (pageInfo.pageNo = 1),
                getList()
            "
          >
            {{ item1.label }}
          </span>
        </div>
        <!-- 公司规模 -->
        <div class="li qiye-type">
          <div class="tit">公司规模:</div>
          <span
              :class="pageInfo.companySize == item2.value ? 'active-c' : ''"
              v-for="(item2, index2) in scalelist"
              :key="index2"
              @click="
              (pageInfo.companySize = item2.value),
                (pageInfo.pageNo = 1),
                getList()
            "
          >
            {{ item2.label }}
          </span>
        </div>
        <!-- 职位类型 -->
        <div class="li qiye-type">
          <div class="tit">职位类型:</div>
          <span
              :class="pageInfo.skillId == item3.value ? 'active-c' : ''"
              v-for="(item3, index3) in hotSkillList"
              :key="index3"
              @click="
              (pageInfo.skillId = item3.value), (pageInfo.pageNo = 1), getList()
            "
          >
            {{ item3.label }}
          </span>
        </div>
        <!-- 地区 -->
        <!-- <div class="li qiye-type">
					<div class="tit">地区:</div>
					<span :class="areanum==item4.id?'active-c':''" v-for="(item4,index4) in arealist" :key="index4" @click="areanum=item4.id">{{item4.name}}</span>
				</div> -->
      </div>
    </div>
    <div class="contentmain">
<!--      <div class="tishi font16" v-if="tn_status === 'no_login'">登录后根据简历精准推荐</div>-->
      <div class="enterprise">
        <!-- 无结果 -->
        <div class="nodata" v-if="hotbusiness===null || hotbusiness.length === 0">
          <img src="../../assets/images/common/position-nodata.png"/>
          <div class="font18" v-if="tn_status === 'no_data'">当前筛选条件下无数据</div>
          <div class="font18" v-if="tn_status === 'wrong_status'">企业信息加载出错</div>
          <div class="font18" v-if="loading">加载中</div>
        </div>
        <el-row>
          <el-col
              style="margin-top: 20px"
              :span="6"
              v-for="(itemqy, index4) in hotbusiness"
              :key="index4"
          >
            <el-card class="qiye">
              <div class="qiye-main" @click="todetail(itemqy.id)">
                <img
                    style="border-radius: 10px"
                    :src="
                    'https://freemen.work/user/file/view/' + itemqy.companyAvatar
                  "
                    @error="setDefaultImage"
                    v-if="itemqy.companyAvatar"
                />
                <img
                    style="border-radius: 10px"
                    src="../../assets/logo.png"
                    v-else
                />
                <div class="qy-name font18" style="text-align: center">{{ itemqy.companyName }}</div>
                <div class="qy-type font14">
                  {{ itemqy.companyType ? itemqy.companyType.itemText : "" }}
                </div>
                <div class="qy-btn font14">
                  <el-button plain
                  ><span>{{ itemqy.jobCount }}</span
                  >个热招职位
                  </el-button
                  >
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <div class="nextpage" style="cursor: revert" v-if="moreBtn && !loginStatus">
          登入可查看更多企业信息
        </div>
        <div class="nextpage" @click="getMoreList" v-if="moreBtn && loginStatus">
          点击加载更多
        </div>
      </div>
      <!-- <div class="paginate" style="position: relative;">
				<el-pagination
					@current-change="handleCurrentChange"
					:current-page="pageInfo.pageNum"
					:page-size="pageInfo.pageSize"
					layout="total, prev, pager, next, jumper"
					:total="pageInfo.count"
				></el-pagination>
			</div> -->
    </div>
  </div>
</template>

<script>
import hidetop from "./hidetop.vue";
import defaultLostImg from '@/assets/logo.png'
export default {
  components: {
    hidetop,
  },
  data() {
    return {
      hotbusiness: [],
      pageInfo: {
        keyword: "",
        companyType: "",
        companySize: "",
        skillId: "",
        pageNo: 1,
        pageSize: 12,
      },
      typelist: [],
      scalelist: [],
      hotSkillList: [],
      areanum: 1,
      arealist: [
        {id: 1, name: "上城区"},
        {id: 2, name: "下城区"},
        {id: 3, name: "萧山区"},
        {id: 4, name: "临平区"},
        {id: 5, name: "西湖区"},
        {id: 6, name: "余杭区"},
        {id: 7, name: "临安区"},
        {id: 8, name: "富阳区"},
      ],
      scrollHeight: 0,
      headerstuts: false,
      moreBtn: false,
      loginStatus: false,
      tn_status: "",
      loading: true,
      scrollToTopFlag: -1,
    };
  },
  watch: {
    scrollToTopFlag(val) {
      //监听路由变化的时候使滚动条回到顶部
      if(val>=0){
        // console.log("@watch - $route - scrollToTopFlag = ", val);
        const scrolltop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if (scrolltop >= 0) {
          window.pageYOffset = 0
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        }
      }
    }
  },
  created() {
    this.scrollToTopFlag =  -1;
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    // console.log("--- created ### get UserInf0 = ", userInfo);
    if (!userInfo) {
      this.loginStatus = false;
    } else {
      this.loginStatus = true;
    }
    this.getType();
    this.getSize();
    this.getHotSkill();
    this.getList();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollx, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollx, true);
  },

  methods: {
    setDefaultImage(e) {
      e.target.src = defaultLostImg;
    },
    getMoreList() {
      //下一页
      this.pageInfo.pageNo++;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.tn_status = "";
      let page = this.pageInfo.pageNo;
      // console.log("---- business, ###  getList this.pageInfo = ", this.pageInfo);
      this.$api.getCompanyList("get", this.pageInfo).then((res) => {
        // console.log("get company list, ###  getCompanyList.res = ", res);
        this.loading = false;
        if (res.code == 10400) {
          this.tn_status = "wrong_status";
          this.hotbusiness = [];
          return;
        }
        if(res.data && res.data.length > 0){
          this.tn_status = ""
        } else {
          this.tn_status = "no_data"
        }
        if (res.data && page > 1) {
          this.hotbusiness = this.hotbusiness.concat(res.data);
        } else {
          this.hotbusiness = res.data;
        }
        if (res.data && res.data.length < 12) {
          this.moreBtn = false;
        } else {
          if(res.data === null){
            this.moreBtn = false;
          } else {
            this.moreBtn = true;
          }
        }
        this.scrollToTopFlag++;
        // console.log("get company list, ####  current business = ", this.hotbusiness);
      });
    },
    getType() {
      this.$api.getdict("get", {code: "company_type"}).then((res) => {
        let arr = [];
        let obj1 = {
          value: "",
          label: "不限",
        };
        arr.push(obj1);
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          arr.push(obj);
        });
        this.typelist = arr;
        this.pageInfo.companyType = this.typelist[0].value;
      });
    },
    getSize() {
      this.$api.getdict("get", {code: "company_size"}).then((res) => {
        let arr = [];
        let obj1 = {
          value: "",
          label: "不限",
        };
        arr.push(obj1);
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          arr.push(obj);
        });
        this.scalelist = arr;
        this.pageInfo.companySize = this.scalelist[0].value;
      });
    },
    getHotSkill() {
      this.$api.getHotSkill("get").then((res) => {
        let arr = [];
        let obj1 = {
          value: "",
          label: "不限",
        };
        arr.push(obj1);
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          arr.push(obj);
        });
        this.hotSkillList = arr;
        this.pageInfo.skillId = this.hotSkillList[0].value;
      });
    },
    handleScrollx() {
      const content = this.$refs.pronbit;
      if (content) {
        this.scrollHeight = content.getBoundingClientRect().top;
        if (this.scrollHeight < 0) {
          this.headerstuts = true;
        } else {
          this.headerstuts = false;
        }
      }
    },
    fetchData(page = 1) {
      // this.table_loading = true;
      this.pageInfo.pageNum = page;
      // this.formInline.pageNum = this.pageInfo.pageNum;
      // this.getdata();
    },
    handleCurrentChange(val) {
      this.fetchData(val);
    },
    todetail(id) {
      console.log(id);
      // this.$router.push({path:'/qiyedetail',query:{id:id,userJobId:this.pageInfo.wantJob}})
      this.$router.push({
        path: "/qiyedetail",
        query: {id: id, userJobId: this.$route.query.userJobId},
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  margin-bottom: 200px;
}

.topbian {
  height: 88px;
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
}

.screenbox {
  // height: 600px;
  background-color: #ffffff;
  padding-bottom: 20px;

  .screenbox-mian {
    width: 1200px;
    margin: 0 auto;
    transform: translate(8px, 0px);

    .li {
      padding-top: 20px;
    }

    .qiye-type {
      color: #111111;
      display: flex;
      align-items: center;

      span {
        margin-left: 30px;
      }

      span:hover {
        cursor: pointer;
        color: #00bcff;
      }

      .active-c {
        color: #00bcff;
      }
    }
  }
}

.nextpage {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
  cursor: pointer;
  margin-top: 15px;
}

// .tishi:hover{
// 			cursor: pointer;
// 		}
.tishi {
  color: #111111;
  margin: 30px 0 10px 0;
}

.enterprise {
  .nodata {
    background-color: #ffffff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 80px 0 130px 0;
    margin-top: 20px;
    margin-bottom: 30px;

    div {
      color: #111111;
      margin-top: 20px;
    }

    img {
      width: 260px;
      height: 220px;
    }
  }

  .nextpage {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999999;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  /deep/ .el-card {
    border-radius: 0px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
  }

  .el-col,
  .el-col,
  .el-col,
  .el-col {
    padding: 0 10px;
  }

  .el-col,
  .el-col {
    padding: 0 10px 0 0;
  }

  .el-col,
  .el-col {
    padding: 0 0 0 10px;
  }

  .qiye:hover {
    transform: scale(1.03);
    background-color: rgba(0, 0, 0, 0.001);
    cursor: pointer;
  }

  .qiye {
    margin-bottom: 30px;

    /deep/ .el-card__body {
      padding: 30px 20px;
    }

    .qiye-main {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 280px;
      // line-height: 2;
      img {
        width: 80px;
        height: 80px;
      }

      .qy-name {
        color: #111111;
        margin-top: 20px;
      }

      .qy-type {
        color: #666666;
        margin-top: 10px;
      }

      .qy-btn {
        margin-top: 30px;

        button {
          height: 36px;
          width: 185px;
          font-size: 14px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #e4e8ee;
          color: #666666;
          border-radius: 0;

          span {
            color: #00bcff;
          }
        }
      }
    }
  }
}

.paginate {
  margin: 60px 0 !important;

  .el-pagination {
    padding: 0 !important;
  }
}

.el-dropdown-link:hover {
  cursor: pointer;
}
</style>
