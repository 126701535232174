<template>
  <div class="contentmain">
    <div class="header-box">
      <div class="hb-left">
        <!-- <el-dropdown style="margin-right: 80px;">
					<span class="el-dropdown-link">
						公司类型<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>国企</el-dropdown-item>
						<el-dropdown-item>私企</el-dropdown-item>
						<el-dropdown-item>个体</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown> -->
        <div class="">
          <span class="el-dropdown-link">
            公司类型<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-select
            v-model="topPageInfo.companyType"
            placeholder="请选择公司类型"
            style="margin: 0 15px"
            @change="change"
          >
            <el-option
              v-for="item in typelist"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <el-dropdown style="margin-right: 80px;">
					<span class="el-dropdown-link">
						公司规模<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>0-20人</el-dropdown-item>
						<el-dropdown-item>20-50人</el-dropdown-item>
						<el-dropdown-item>100以上</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown> -->
        <div class="">
          <span class="el-dropdown-link">
            公司规模<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-select
            v-model="topPageInfo.companySize"
            placeholder="请选择公司规模"
            @change="change"
            style="margin: 0 15px"
          >
            <el-option
              v-for="item in scalelist"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @change="change"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <el-dropdown>
					<span class="el-dropdown-link">
						职位类型<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>前端</el-dropdown-item>
						<el-dropdown-item>后端</el-dropdown-item>
						<el-dropdown-item>测试</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown> -->
        <div class="">
          <span class="el-dropdown-link">
            职位类型<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-select
            v-model="topPageInfo.skillId"
            placeholder="请选择职位类型"
            style="margin: 0 15px"
            @change="change"
          >
            <el-option
              v-for="item in hotSkillList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @change="change"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="hb-right">
        <!-- <el-checkbox v-if="false">
					<span style="color: #409EFF;">登录可精准匹配</span>
				</el-checkbox>
				<div class="">
					<el-checkbox style="margin-right: 30px;">
						<span style="color: #409EFF;">求职意向</span>
					</el-checkbox>
					<el-dropdown>
						<span class="el-dropdown-link" >
							Java 10-15K<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item>前端</el-dropdown-item>
							<el-dropdown-item>后端</el-dropdown-item>
							<el-dropdown-item>测试</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["pageInfo", "typelist", "hotSkillList", "scalelist"],
  data() {
    return {
      topPageInfo: {},
    };
  },

  created() {
    this.topPageInfo = this.pageInfo;
  },
  methods: {
    change() {
      this.$emit("change");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input__inner {
  height: 55px;
  border-radius: 6px;
}
/deep/.el-range-separator {
  color: #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 88px;
  // background-color: #000080;
  transform: translate(-9px, 0px);
  .hb-left {
    display: flex;
    align-items: center;
  }

  /deep/ .el-button {
    width: 133px;
    height: 54px;
    border-radius: 6px;
    background-color: #00bcff;
    border: none;
  }
}
.el-dropdown-link {
  color: #111111 !important;
  font-size: 16px;
}
.el-dropdown-link:hover {
  cursor: pointer;
}
</style>
